import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  cancelOrder,
  getOrder,
  resetCancel,
} from "../../store/orders/OrderSlice";
import Cookies from "js-cookie";
import "./orders.css";
import { Button, Skeleton } from "@mui/material";
import { FaReceipt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Result } from "antd";

function  OrderComponent() {
  const { isCancelled, isLoading, orders } = useSelector(
    (state) => state?.orders
  );
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(5)
  const [noOfBookingCount,setNoOfBookingCount] = useState()
  

  const cid = JSON.parse(Cookies.get("udtl")).uid;

  const cancelOrders = (res) => {
    const data = {
      _id: cid,
      email: res.customerData[0].email,
      paymentReference: res.paymentReference,
      id: res._id,
      paymentRefNo: res.paymentId,
      cancelationAmount:
        parseFloat(res.totalAmount) - parseFloat(res.totalAmount) * 0.1,
    };
    dispatch(cancelOrder(data));
  };
  useEffect(()=>{
    setNoOfBookingCount((orders?.count)-(orders?.data?.length))
  },[(orders?.count),(orders?.data?.length)])

  console.log("length",orders?.data?.length)
  console.log(noOfBookingCount)
  
  const loadMoreAction = () => {
    // setNoOfBookingCount((noOfBookingCount-orders?.data?.length)>0?noOfBookingCount-orders?.data?.length:0)

    setPageCount(pageCount + 5);
    const data = {
      _id: cid,
      pageCount: pageCount + 10,
    };
    dispatch(getOrder(data));
  };
  useEffect(() => {
    const data = {
      _id: cid,
      pageCount: 5,
    };
    dispatch(getOrder(data));
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   const data = {
  //     _id: cid,
  //   };
  //   dispatch(getOrder(data));
  //   // eslint-disable-next-line
  // }, []);
  // const cancelAction = (res) => {
  //   const data = {
  //     tripId: res,
  //   };
  //   dispatch(cancelOrder(data));
  //   window.location.reload();
  // };

  useEffect(() => {
    // const data = {
    //   _id: cid,
    // };
    // dispatch(getOrder(data))
    // eslint-disable-next-line
  }, []);
  

  return (
    <div>
      {isLoading ? (
        <div className="d-flex flex-column">
          {/* <div> */}
            <Skeleton height={250} />
          {/* </div> */}
          <Skeleton height={250} />
          <Skeleton height={250} />
        </div>
      ) : (
        <div>
          {orders?.data?.map((res, i) => (
            <div className="order-order-card order-order-boxx">
              <div className="d-flex gap-2 justify-content-between pt-2 pb-3">
                <div>
                  <div className="gap-2 order-place-container">
                    <div style={{ fontSize: "18px", fontWeight: "700" }}>
                      {res.source}
                    </div>
                    <div style={{ fontSize: "18px", fontWeight: "700" }}>
                      - {res.destination}
                    </div>
                  </div>
                  <div className="d-flex gap-2 align-items-end">
                    <div style={{ fontSize: "12px", fontWeight: "400" }}>
                      Trip No ---
                    </div>
                    <div style={{ fontSize: "12px", fontWeight: "400" }}>
                      {res.tripNo}
                    </div>
                  </div>
                </div>
                <div className="mobHide">
                  {/* <Button
                style={{
                  background: "#c49b6b",
                  color: "white",
                  fontWeight: "900",
                }}
              >
                Manage Booking
              </Button> */}
                </div>
              </div>
              <div className="order-ticket-border mt-2 mb-2">
                {res.tripStatus}
              </div>
              <div className=" pt-2 pb-3 order-place-container justify-content-between">
                <div>
                  <div style={{ fontSize: "10px" }}>Ordered on</div>
                  <div style={{ fontSize: "16px", fontWeight: "700" }}>
                    {moment(res.created_at).format("MM-DD-YYYY, hh:mm A")}
                  </div>
                </div>
                <div>
                  <div style={{ fontSize: "10px" }}>Reserved on</div>
                  <div style={{ fontSize: "16px", fontWeight: "700" }}>
                    {moment(Date(res.scheduledDate)).format("MM-DD-YYYY")},{" "}
                    {res.scheduledTime}
                  </div>
                </div>
                <div>
                  <div style={{ fontSize: "10px" }}>VEHICLE</div>
                  <div style={{ fontSize: "16px", fontWeight: "700" }}>
                    {res.vehicle[0].vehicleName}
                  </div>
                </div>
                <div>
                  <div style={{ fontSize: "10px" }}>DRIVER</div>
                  <div style={{ fontSize: "18px", fontWeight: "700" }}>
                    {res?.drivers[0]?.firstName} {res?.drivers[0]?.lastName}
                  </div>
                </div>
                {/* <div
              style={{ fontWeight: "600", color: "#008df9", cursor: "pointer" }}
              className="mobHide"
            >
              <FaReceipt /> Download Invoice
            </div> */}
              </div>
              {/* <div>
                {res.tripStatus === "Booked" ? (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => cancelOrders(res)}
                  >
                    Cancel
                  </Button>
                ) : null}
              </div> */}
            </div>
          ))}
          {noOfBookingCount>0?
          (orders?.data?.length>=5?(
            <div className="text-center" onClick={() => loadMoreAction()}>
              <Button variant="contained">Load More</Button>
            </div>
          ) : (null)):null
          }
        </div>
      )}
    </div>
  );
}

export default OrderComponent;
