import React from "react";
import MapWithDirections from "../components/MapWithDirections";

function Test() {
  return (
    <div>
      <h1>Test</h1>
      <MapWithDirections />
    </div>
  );
}

export default Test;
